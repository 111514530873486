import gsap from 'gsap';

export default function showTitle(scrollTween, wrapTitle) {
  
  const title = wrapTitle.querySelector('.slide-title-animation__title');

  const showTitleTL = gsap.timeline({
    scrollTrigger: {
      trigger: wrapTitle,
      scrub: true,
      containerAnimation: scrollTween,
      start: 'left 100%',
      end: 'right 80%',
    }
  });

  const timeline = gsap.timeline();
  timeline
  .set(title, {
    autoAlpha: 0
  })
  .to(title, {
    scale: 3,
    autoAlpha: 1,
    duration: 2.5
  }, ">-25%");

  showTitleTL.add(timeline);
  
  return showTitleTL;
}