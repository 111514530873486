import gsap from 'gsap';

export default function showText(scrollTween, wrapText) {
  
  let title = wrapText.querySelector('.slide-text-animation__title');
  let titleH = title.offsetHeight;
  let text = wrapText.querySelector('.slide-text-animation__text');
  const headerH = window.innerHeight / 100 * 15;
  let titleToTop = title.getBoundingClientRect().y;
  let setTopTitle = titleToTop-headerH-20;
  let setTopText = setTopTitle-titleH-35;
  const animation = wrapText.querySelector('.slide-text-animation__video');

  const showTextTL = gsap.timeline({
    scrollTrigger: {
      trigger: wrapText,
      scrub: true,
      containerAnimation: scrollTween,
      start: 'left 100%',
      end: 'right 80%'
    }
  });

  const timeline = gsap.timeline();
  timeline
  .set(title, {
    y: - titleH / 2
  })
  .set(text, {
    y: - setTopText,
    autoAlpha: 0
  })
  .to(title, {
    y: - setTopTitle,
    duration: 2,
    onStart: () => {
      animation.pause();
    }
  }, 2.5)
  .to(text, {
    autoAlpha: 1,
    duration: 2.5
  }, ">-50%")
  .add(() => animation.play(), "<-10%");

  showTextTL.add(timeline);
  
  return showTextTL;
}