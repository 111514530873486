import $ from 'jquery'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function bgColorSection(scrollMedia) {
  gsap.registerPlugin(ScrollTrigger);

  const container = document.querySelector('#page-wrap');
  const bgSection = gsap.utils.toArray(".bg-section");
  const colorTarget = $('.color-contrast');

  let activeSection = 0;

  const lastSection = bgSection[bgSection.length - 1];
  const arrowNav = $('.arrow-nav');

  const scrollBgAndArrow = (mobile) => {
    bgSection.forEach((bg, i) => {
      ScrollTrigger.create({
        trigger: bg,
        scrub: true,
        containerAnimation: mobile ? undefined : scrollMedia.scrollTween,
        onEnter: () => {
          const prevColor = $(bgSection[activeSection]).data('colorbtn');
          activeSection = activeSection < i ? i : activeSection;
          const currentColor = $(bgSection[activeSection]).data('colorbtn');
          colorTarget.removeClass(`color-contrast--${prevColor}`)
          colorTarget.addClass(`color-contrast--${currentColor}`)
        },
        onLeaveBack: () => {
          const prevColor = $(bgSection[activeSection]).data('colorbtn');
          activeSection = activeSection - 1;
          const currentColor = $(bgSection[activeSection]).data('colorbtn');
          colorTarget.removeClass(`color-contrast--${prevColor}`)
          colorTarget.addClass(`color-contrast--${currentColor}`)
        },
        onUpdate: self => {
          const movingForward = self.direction > 0;
          const isInFirstSlide = document.documentElement.scrollTop < window.innerWidth;
          const isInLastSlide = document.documentElement.scrollTop > container.scrollWidth - window.innerWidth;
          const isStartProgess = document.documentElement.scrollTop < window.innerWidth  * 0.1;
          const isEndProgess = document.documentElement.scrollTop > container.scrollWidth - window.innerWidth * 0.1;

          if ((movingForward && (!isInLastSlide || !isEndProgess)) || (isInFirstSlide && isStartProgess)) {
            arrowNav.removeClass(`arrow-nav--reverse`);
          } else {
            arrowNav.addClass(`arrow-nav--reverse`);
          }

          if ((!isInLastSlide || !isEndProgess)) {
            arrowNav.removeClass(`arrow-nav--end`);
          } else {
            arrowNav.addClass(`arrow-nav--end`);
          }
        },
        start: mobile ? 'top 0' : (i%2 === 0 || i === bgSection.length-1 ? 'left 80%' : 'left 100%'),
        end: mobile ? 'bottom 0' : 'right 0',
      });
    });
  }

  ScrollTrigger.matchMedia({
    "(min-width: 992px)": () => {
      scrollBgAndArrow(false)
    },
    "(max-width: 991px)": () => {
      scrollBgAndArrow(true)
    }
  });
}