import gsap from 'gsap';

export default function company(scrollTween, wrapCompany) {

  const companyImg = wrapCompany.querySelector('.company__img');

  const companyImgTL = gsap.timeline({
    scrollTrigger: {
      trigger: wrapCompany,
      scrub: true,
      containerAnimation: scrollTween,
      start: 'left 100%',
      end: 'right 20%'
    }
  });

  const timeline = gsap.timeline();
  timeline
  .to(companyImg, {
    left: "0",
    duration: 2.5
  }, 2.5);

  companyImgTL.add(timeline);

  return companyImgTL;
}