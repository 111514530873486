import $ from 'jquery';

import sectionScroll from './section-scroll';
// import drag from './draggable';
import logo from './logo';
import bgColorSection from './bgSection';
import arrowNav from './arrow-nav';
import circlesPattern from './circles-pattern';
import rectPattern from './rect-pattern';
import navigation from './navigation';
import companyModal from './company-modal';
import progettoModal from './progetto-modal';
import formModal from './form-modal'
import slider from './slider'
import blogList from './blog-list'
import loading from './loading'
import specializzazione from './specializzazione'

loading();

$(window).on('load', function(){
  if ($('#page-wrap').length > 0) {
    const scrollMedia = sectionScroll();
    // drag(scrollMedia);
    logo(scrollMedia);
    bgColorSection(scrollMedia);
    arrowNav(scrollMedia);
    circlesPattern();
    rectPattern();
    navigation(scrollMedia);
    companyModal();
    formModal();
    slider();
    specializzazione();
  }
  
  progettoModal();
  blogList();
});

