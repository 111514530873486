import $ from 'jquery';

export default function loading() {
  
  let loadContent = false;

  $(window).on('load', function(){
    loadContent = true;
    $('body').removeClass('block-site');
    $('.loading').addClass('hide');
    $('iframe[data-src]').each(function() {
      $(this).attr("src",$(this).data("src"));
    });
  });
  
  $(function() {
    console.log("ready");
    if(!loadContent && $('.loading').length > 0) {
      $('body').addClass('block-site');   
    }
  });
}