import $ from 'jquery';
import gsap from 'gsap';

export default function arrowNav(scrollMedia) {

  let i = 1;
  let animating = false;

  var moveArrow = setInterval(function (){
    if (!animating) {
      i++;
    }
    // animo la freccia dopo 5 secondi di "inattività"
    if (i > 5) {
      i = 0;
      $('.arrow-nav .arrow-nav__btn').addClass('moveArrow');
      animating = true;
      setTimeout(function() {
        i = 0;
        $('.arrow-nav .arrow-nav__btn').removeClass('moveArrow');
        animating = false;
      }, 6000);
    }

    $(document).on('scroll', function() {
      i = 0;
    });
  }, 1000);

  // Back to start
  $(document).on('click', '.arrow-nav.arrow-nav--end .arrow-nav__btn', function(e) {
    e.preventDefault();

    const panelsContainer = document.querySelector("#page-wrap");
    const targetElem = $(this).data('scroll');

    gsap.to(window, {
      scrollTo: {
        y: scrollMedia.mobile ? targetElem.offsetTop : targetElem.offsetLeft*(panelsContainer.scrollWidth/(panelsContainer.scrollWidth-window.innerWidth)),
        autoKill: false
      },
      duration: 1.5
    });
  });

  // Back to start (smartphone)
  $('.arrow-nav-sm .arrow-nav-sm__btn').on('click', function(e) {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: 0
    },'slow');
  });
  
}