import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

export default function rectPattern() {
  const container = document.querySelector('#page-wrap');
  const mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

  window.addEventListener("mousemove", e => {
    mouse.x = e.x;
    mouse.y = e.y;
  });

  // Ratio between scroll and horizontal mouse position
  let scrollRatio = (container.scrollWidth - window.innerWidth) / container.scrollWidth;

  const canvas = document.querySelector('#rect-pattern');
  let canvasWidth = canvas.clientWidth;
  let canvasHeight = canvas.clientHeight;
  canvas.setAttribute('width', canvasWidth);
  canvas.setAttribute('height', canvasHeight);
  const rows = 7;
  let columns = Math.ceil(rows * canvasWidth / canvasHeight);

  const rectsState = new Map();
  let canvasY = canvas.getBoundingClientRect().y;
  let { x: textRectX, y: textRectY, width: textRectWidth, height: textRectHeight } = document.querySelector('.rect-pattern-text').getBoundingClientRect()
  textRectX += window.scrollY * scrollRatio;
  window.addEventListener('resize', () => {
    setTimeout(() => {
      scrollRatio = (container.scrollWidth - window.innerWidth) / container.scrollWidth;
      canvasWidth = canvas.clientWidth;
      canvasHeight = canvas.clientHeight;
      canvas.setAttribute('width', canvasWidth);
      canvas.setAttribute('height', canvasHeight);
      columns = Math.ceil(rows * canvasWidth / canvasHeight);
      canvasY = canvas.getBoundingClientRect().y;
      const newTextRect = document.querySelector('.rect-pattern-text').getBoundingClientRect();
      textRectX = newTextRect.x + window.scrollY * scrollRatio;
      textRectY = newTextRect.y;
      textRectWidth = newTextRect.width;
      textRectHeight = newTextRect.height;
      rectsState.clear();
    }, 500);
  });

  const canvasElem =  document.getElementById('rect-pattern');
  const ctx = canvasElem.getContext('2d');
  
  const round = number => (0.5 + number) << 0;

  function drawCanvas() {
    ctx.fillStyle = '#251ffa';

    const wc = canvasWidth / columns;
    const hc = canvasHeight / rows;
    const interpolate = (value, target, delta) => Math.abs(value - target) < delta ? target : Math.sign(target - value) * delta + value;
    const mouseX = mouse.x + window.scrollY * scrollRatio;
    const mouseY = mouse.y - canvasY;
    const minTextX = textRectX - wc ;
    const maxTextX = textRectX + textRectWidth;
    const maxTextY = textRectY - canvasY + textRectHeight;
    const minTextY = textRectY - canvasY - hc;

    for (let j = 0; j < columns; j++) {
      for (let i = 0; i < rows; i++) {
        const x = j * wc;
        const y = i * hc;
        if (x < maxTextX && x > minTextX && y < maxTextY && y > minTextY) {
          continue;
        }
        const prevRotation = rectsState.get(i + j * 100);
        let rotation = 0;
        if (Math.abs(mouseX - x - wc * 0.5) < 2 * wc && Math.abs(mouseY - y - hc * 0.5) < 2 * hc) {
          rotation = interpolate(prevRotation, Math.atan2(mouseY - y - hc * 0.5, mouseX - x - wc * 0.5) - Math.PI / 2, Math.PI / 20);
        } else if (prevRotation) {
          rotation = interpolate(prevRotation, 0, Math.PI / 30);
        }
        if (prevRotation !== rotation) {
          ctx.clearRect(x, y, wc, hc);
          ctx.save();
          ctx.translate(round(x + wc * 0.5), round(y + hc * 0.5));
          ctx.rotate(rotation);
          ctx.translate(-round(wc * 0.5)+round(wc * 0.425), -round(hc * 0.5)+round(hc * 0.1));
          ctx.fillRect(0, 0, round(wc * 0.15), round(hc * 0.8));
          rectsState.set(i + j * 100, rotation);
          ctx.restore();
        }
      }
    }
  }

  let isDrawing = false;
  const checkCanvasInviewPort = function () {
    if (ScrollTrigger.isInViewport(canvasElem, 0, true)) {
      if (!isDrawing) {
        gsap.ticker.add(drawCanvas);
        isDrawing = true;
      }
    } else if (isDrawing) {
      gsap.ticker.remove(drawCanvas);
      isDrawing = false;
    }
  }
  gsap.ticker.add(checkCanvasInviewPort);
}