import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

export default function rectPattern() {
  const canvas = document.querySelector('#circles-pattern');
  let canvasWidth = canvas.clientWidth;
  let canvasHeight = canvas.clientHeight;
  canvas.setAttribute('width', canvasWidth);
  canvas.setAttribute('height', canvasHeight);

  const rectsState = new Map();
  let canvasY = canvas.getBoundingClientRect().y;
  window.addEventListener('resize', () => {
    setTimeout(() => {
      canvasWidth = canvas.clientWidth;
      canvasHeight = canvas.clientHeight;
      canvas.setAttribute('width', canvasWidth);
      canvas.setAttribute('height', canvasHeight);
      canvasY = canvas.getBoundingClientRect().y;
      rectsState.clear();
    }, 500);
  });

  const canvasElem =  document.getElementById('circles-pattern');
  const ctx = canvasElem.getContext('2d');
  
  const round = number => (0.5 + number) << 0;

  const colors = ['rgba(21,39,253, 1)', 'rgba(72,51,192, 1)', 'rgba(41,228,79, 1)', 'rgba(20,191,209, 1)'];
  
  function drawCanvas() {
    ctx.fillStyle = '#1C8DBC';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    const crR = round(0.95*canvasHeight);

    for (let i = 0; i < 20; i++) {
      const state = rectsState.get(i);
      let x, y, rx, ry, rotation, color;
      if (state) {
        x = state.x;
        y = state.y;
        rx = state.rx;
        ry = state.ry;
        rotation = state.rotation;
        color = state.color;
      } else {
        x = Math.random()*canvasWidth;
        y = Math.random()*canvasHeight;
        rx = (Math.random()*0.5 - 0.25)*canvasWidth;
        ry = (Math.random()*0.5 - 0.25)*canvasHeight;
        rotation = Math.random()*2*Math.PI;
        color = colors[Math.floor(Math.random()*colors.length)];
      }
      ctx.save();
      ctx.rotate(rotation);
      ctx.translate(round(rx), round(ry));

      rotation += 0.05*Math.PI/60;
      
      const crX = round(x+0.5*canvasWidth);
      const crY = round(x+0.5*canvasHeight);
      
      const grd = ctx.createRadialGradient(crX, crY, 0, crX, crY, crR);
      grd.addColorStop(0, color);
      grd.addColorStop(1, color.replace('1)', '0)'));
      ctx.fillStyle = grd;
      ctx.beginPath();
      ctx.arc(crX, crY, crR, 0, 2 * Math.PI);
      ctx.fill();
      rectsState.set(i, { x, y, rx, ry, rotation, color });
      ctx.restore();
    }
  }

  let isDrawing = false;
  const checkCanvasInviewPort = function () {
    if (ScrollTrigger.isInViewport(canvasElem, 0, true)) {
      if (!isDrawing) {
        gsap.ticker.add(drawCanvas);
        isDrawing = true;
      }
    } else if (isDrawing) {
      gsap.ticker.remove(drawCanvas);
      isDrawing = false;
    }
  }
  gsap.ticker.add(checkCanvasInviewPort);
}