import $ from 'jquery';
import gsap from 'gsap';
import getPosition from './get-position';

export default function progettoModal() {
  
  const progetto = document.querySelectorAll(".progetto");
  let lastProgeto;

  for (var i = 0; i < progetto.length; i++) {
    progetto[i].addEventListener("click", openModal);
  }

  // OPEN MODAL PROGETTO
  function openModal() {
    var idModal = this.getAttribute('data-idModal');
    var modalProgetto = document.getElementById(idModal);
    var titleModal = modalProgetto.querySelector('.progetto-modal__title');
    var textModal = modalProgetto.querySelector('.progetto-modal__text');
    var newRect = getPosition(modalProgetto, this);
    lastProgeto = this;

    $('body').addClass('block-site');

    gsap.set(modalProgetto, {
      x: newRect.left,
      y: newRect.top,
      width: newRect.width,
      height: newRect.height,
      zIndex: 110
    });

    var showProgettoModalTL = gsap.timeline();
    showProgettoModalTL
    .set(titleModal, {
      autoAlpha: 0,
      duration: 0
    })
    .set(textModal, {
      autoAlpha: 0,
      duration: 0
    })
    .to(modalProgetto, {
      x: 0,
      y: 0,
      width: "100vw",
      height: "100vh",
      autoAlpha: 1
    })
    .to(titleModal, {
      autoAlpha: 1,
      duration: 0.5
    })
    .to(textModal, {
      autoAlpha: 1,
      duration: 1.5
    });
  }
  
  // CLOSE MODAL PROGETTO
  $(document).on('click', '.progetto-modal .close', function() {
    var idModal = this.getAttribute('data-idModal');
    var modalProgetto = document.getElementById(idModal);
    var textModal = modalProgetto.querySelector('.progetto-modal__text');
    var newRect = getPosition(modalProgetto, lastProgeto);

    $('body').removeClass('block-site');
    
    var hideProgettoModalTL = gsap.timeline();
    hideProgettoModalTL
    .set(textModal, {
      autoAlpha: 0,
      duration: 0
    }, "<-10%")
    .to(modalProgetto, {
      x: newRect.left,
      y: newRect.top,
      height: newRect.height,
      width: newRect.width,
      autoAlpha: 0,
      zIndex: -1
    });
  });

}