import $ from 'jquery'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function logo(scrollMedia) {
  gsap.registerPlugin(ScrollTrigger);

  const bgSection = gsap.utils.toArray(".bg-section");
  const logoTarget = $('.logo');

  const changeColor = (prev, actual) => {
    const prevColor = $(bgSection[prev]).data('colorbtn');
    const currentColor = $(bgSection[actual]).data('colorbtn');
    logoTarget.removeClass(`color-contrast--${prevColor}`);
    logoTarget.addClass(`color-contrast--${currentColor}`);
  }

  let activeSection = 0;
  const scrollLogo = (mobile) => {
    ScrollTrigger.create({
      trigger: document.querySelector('section'),
      scrub: true,
      containerAnimation: scrollMedia.mobile ? undefined : scrollMedia.scrollTween,
      onLeave: () => {
        $(".logo").removeClass("logo--extended");
      },
      onEnter: () => {
        $(".logo").addClass("logo--extended");
      },
      onEnterBack: () => {
        $(".logo").addClass("logo--extended");
      },
      start: scrollMedia.mobile ? 'top 0': 'left 0',
      end: scrollMedia.mobile ? 'bottom 0' : 'right 0',
    });

    bgSection.forEach((bg, i) => {
      ScrollTrigger.create({
        trigger: bg,
        scrub: true,
        containerAnimation: mobile ? undefined : scrollMedia.scrollTween,
        onLeave: () => {
          // console.log("onLeave", i);
          const prev = activeSection;
          activeSection = i+1;
          changeColor(prev, activeSection);
        },
        onEnterBack: () => {
          // console.log("onEnterBack", i);
          const prev = activeSection;
          activeSection = i;
          changeColor(prev, activeSection);
        },
        start: mobile ? 'top 100%': (i%2 === 0 && i !== bgSection.length-2 ? 'left 1px': 'left -15%'),
        end: mobile ? 'bottom 100%':  (i%2 === 0 && i !== bgSection.length-2 ? 'right 1px': 'right -15%'),
      });
    });
  }

  ScrollTrigger.matchMedia({
    "(min-width: 992px)": () => {
      scrollLogo(false)
    },
    "(max-width: 991px)": () => {
      scrollLogo(true)
    }
  });
  
}