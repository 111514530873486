import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import showText from './show-text';
import showTitle from './show-title';
import company from './company';


export default function sectionScroll() {
  gsap.registerPlugin(ScrollTrigger);

  const container = document.querySelector('#page-wrap');
  const sections = gsap.utils.toArray(".section-scroll");

  const result = {
    mobile: true
  }
  ScrollTrigger.matchMedia({
    "(min-width: 992px)": () => {
      const scrollTween = gsap.to(sections, {
        x: () => `-${container.scrollWidth - window.innerWidth}`,
        ease: "none",
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: true,
          end: () => `+=${container.scrollWidth}`,
          invalidateOnRefresh: true
        }
      });

      //animazione comparsa titolo+testo
      const showTextTls = gsap.utils.toArray('.slide-text-animation').map(wrapText => showText(scrollTween, wrapText));
      //animazione comparsa titolo
      const showTitletTls = gsap.utils.toArray('.slide-title-animation').map(wrapTitle => showTitle(scrollTween, wrapTitle));
      //animazione immagini gruppo
      const companyTls = gsap.utils.toArray('.company').map(wrapCompany => company(scrollTween, wrapCompany));

      result.scrollTween = scrollTween;
      result.mobile = false;

      return () => {
        result.mobile = true;
        showTextTls.concat(showTitletTls).concat(companyTls).forEach(tl => tl.kill());
      }
    }
  });
  return result;
}