import $ from 'jquery'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default function navigation(scrollMedia) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  var $navBtn = $('#navBtn');

  gsap.set('.navigation__nav',{xPercent:-50, yPercent:-50})
  gsap.set('.navigation__item',{x:-800});
  gsap.set('.navigation__contact',{autoAlpha:0});
  gsap.set('.navigation__social',{autoAlpha:0});

  var navAmination = new gsap.timeline()
  .set('.btn-nav, .logo__double-slash, .logo__ariadne, .logo__group, .btn-nav, .btn-label, .btn-nav .stroke-color', { clearProps: "all" })
  .to('.btn-nav, .btn-label', {color: '#090909'},0)
  .to('.btn-nav .stroke-color', {stroke: '#090909'},0)
  .to('.btn-nav .btn-label-menu', {y: "100%"},0)
  .to('.btn-nav .btn-label-close', {y: "-100%"},0)
  .to('.btn-nav .btn-icon', {rotation: 45},0)
  .to('.arrow-nav', {autoAlpha:0},0)
  .to('.navigation', {duration: 0.2, autoAlpha:1},0)
  .to('.navigation__nav .navigation__item', {duration: 0.2, x:0, stagger: 0.2}, 0.5)
  .to('.navigation__contact', {duration: 0.5, autoAlpha:1 })
  .to('.navigation__social', {duration: 0.5, autoAlpha:1 })
  .pause()

  let open = false;
  $navBtn.on('click', function(e) {
    $('body').toggleClass('block-site');
    $(this).toggleClass('active');
    if (open) {
      navAmination.timeScale(5).reverse();
    } else {
      navAmination.timeScale(1).play();
    }
    open = !open;
  });

  //Navigazione menu
  const panelsContainer = document.querySelector("#page-wrap");
  document.querySelectorAll(".navigation__link").forEach(anchor => {
    anchor.addEventListener("click", function(e) {
      e.preventDefault();
      open = false;
      $('body').removeClass('block-site');
      navAmination.restart().pause();

      const targetElem = document.querySelector(e.target.getAttribute("href"));
      if(targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
        gsap.to(window, {
          scrollTo: {
            y: scrollMedia.mobile ? targetElem.offsetTop :targetElem.offsetLeft*(panelsContainer.scrollWidth/(panelsContainer.scrollWidth-window.innerWidth)),
            autoKill: false
          },
          duration: 1
        });
      }
    });
  });

  //Bottone "contattaci"
  document.querySelectorAll(".contact-btn").forEach(anchor => {
    anchor.addEventListener("click", function(e) {
      e.preventDefault();
      const anchorToScroll = $(this).attr('href');
      open = false;
      $('body').removeClass('block-site');
      navAmination.restart().pause();

      const targetElem = document.querySelector(anchorToScroll);
      if(targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
        gsap.to(window, {
          scrollTo: {
            y: scrollMedia.mobile ? targetElem.offsetTop :targetElem.offsetLeft*(panelsContainer.scrollWidth/(panelsContainer.scrollWidth-window.innerWidth)),
            autoKill: false
          },
          duration: 1
        });
      }
    });
  });

  //Logo (back to start)
  document.querySelectorAll(".logo").forEach(anchor => {
    anchor.addEventListener("click", function(e) {
    e.preventDefault();
    const anchorToScroll = $(this).data('scroll');
    open = false;
    $('body').removeClass('block-site');
    navAmination.restart().pause();

    const targetElem = document.querySelector(anchorToScroll);
    if(targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
      gsap.to(window, {
        scrollTo: {
          y: scrollMedia.mobile ? targetElem.offsetTop : targetElem.offsetLeft*(panelsContainer.scrollWidth/(panelsContainer.scrollWidth-window.innerWidth)),
          autoKill: false
        },
        duration: 1.5
      });
    }
  });
});
  
}