import gsap from 'gsap';

export default function getPosition(elem, target) {
  
  var targetRect = target.getBoundingClientRect();
  var elemRect = elem.getBoundingClientRect();

  gsap
  .set(elem, {
    x: 0,
    y: 0,
    width: targetRect.width,
    height: targetRect.height
  });
  
  var newRect = elem.getBoundingClientRect();
  gsap.set(elem, { width: elemRect.width, height: elemRect.height });

  return {
    left: targetRect.left - newRect.left,
    top: targetRect.top - newRect.top,
    width: newRect.width,
    height: newRect.height
  };

}