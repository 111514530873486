import $ from 'jquery';
import gsap from 'gsap';
import getPosition from './get-position';

export default function companyModal() {
  
  const company = document.querySelectorAll(".company");
  let lastCompany;

  for (var i = 0; i < company.length; i++) {
    company[i].addEventListener("click", openModal);
  }

  // OPEN MODAL COMPANY
  function openModal() {
    var idModal = this.getAttribute('data-idModal');
    var modalCompany = document.getElementById(idModal);
    var newRect = getPosition(modalCompany, this);
    lastCompany = this;

    $('body').addClass('block-site');

    gsap.set(modalCompany, {
      x: newRect.left,
      y: newRect.top,
      width: newRect.width,
      height: newRect.height
    });

    var showCompanyModalTL = gsap.timeline();
    showCompanyModalTL.to(modalCompany, {
      x: 0,
      y: 0,
      width: "100vw",
      height: "100vh",
      autoAlpha: 1,
      zIndex: 110
    });
  }
  
  // CLOSE MODAL COMPANY
  $(document).on('click', '.company-modal .close', function() {
    var idModal = this.getAttribute('data-idModal');
    var modalCompany = document.getElementById(idModal);
    var newRect = getPosition(modalCompany, lastCompany);

    $('body').removeClass('block-site');
    
    var hideCompanyModalTL = gsap.timeline();
    hideCompanyModalTL.to(modalCompany, {
      x: newRect.left,
      y: newRect.top,
      height: newRect.height,
      width: newRect.width,
      autoAlpha: 0,
      zIndex: -1
    });
  });

}