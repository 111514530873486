import $ from 'jquery';

export default function specializzazione() {
  
  function init() {

    let mediaQueryDesktop = window.matchMedia('(min-width: 992px)');

    function matchMediaCallback(mediaQueryDesktop) {
      if(mediaQueryDesktop.matches) {
        $(document).on('mouseenter mouseleave', '.box-specializzazione .box-specializzazione__title', function () {
          $(".box-specializzazione__baloon").toggleClass("disabled");
          $(this).parents(".box-specializzazione__baloon").removeClass("disabled");
          $(this).parents(".box-specializzazione__baloon").toggleClass("hover");
        });
      } else {
        $('.box-specializzazione__baloon').on('click', '.box-specializzazione__title', function() {
          $(this).parent('.box-specializzazione__baloon').toggleClass('open');
        });
      }
    }

    mediaQueryDesktop.addEventListener('change', matchMediaCallback);
    matchMediaCallback(mediaQueryDesktop);
  }

  $( document ).ready(function() {

    if($('.box-specializzazione .box-specializzazione__baloon').length > 0) {
      init();
    }

  });
}