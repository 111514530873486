import $ from 'jquery';
import 'slick-carousel';

export default function slider() {
  
  function sliderSmartphone() {

    var settingsSliderSmartphone = {
      arrows: false,
      dots: false,
      infinite: false,
      autoplay: false,
      slidesToShow: 20,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        }
      ]
    };
    
    var sliderSmartphoneList =  $('.slider-smartphone').slick(settingsSliderSmartphone);
    $('.slider-smartphone').slick(settingsSliderSmartphone);

    $(window).on('resize', function() {
      if( $(window).width() < 993 && !sliderSmartphoneList.hasClass('slick-initialized')) {
        $('.slider-smartphone').slick(settingsSliderSmartphone);
      }
    });
  }

  $( document ).ready(function() {

    if($('.slider-smartphone').length > 0) {
      sliderSmartphone();
    }

  });
}