import $ from 'jquery';
import gsap from 'gsap';
import getPosition from './get-position';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_it.min.js';

export default function formModal() {

  const formModal = document.getElementById("form-modal");
  const numStep = $('.form-step__fieldset').length;
  let openFormModal;

  //OPEN FORM MODAL
  $('.slide-form').on('click', '.section-scroll__btn', function() {
    $('body').addClass('block-site');
    var newRect = getPosition(formModal, this);
    openFormModal = this;

    gsap.set(formModal, {
      x: newRect.left,
      y: newRect.top,
      width: newRect.width,
      height: newRect.height
    });

    var showFormModalTL = gsap.timeline();
    showFormModalTL.to(formModal, {
      x: 0,
      y: 0,
      width: "100vw",
      height: "100vh",
      autoAlpha: 1,
      zIndex: 110
    });
  });

  // CLOSE FORM MODAL
  $(document).on('click', '.form-modal .close', function() {
    $('body').removeClass('block-site');
    var newRect = getPosition(formModal, openFormModal);
    
    var hideFormModalTL = gsap.timeline();
    hideFormModalTL.to(formModal, {
      x: newRect.left,
      y: newRect.top,
      height: newRect.height,
      width: newRect.width,
      autoAlpha: 0,
      zIndex: -1
    });
  });
  
  // VALIDAZIONE FORM
  const validateForm = $("#form-richiesta").validate({
    lang: 'it',
    rules: {
      name: {
        required: true,
        minlength: 2
      },
      surname: {
        required: true,
        minlength: 2
      },
      company: {
        required: true,
        minlength: 2
      },
      job: {
        required: true,
        minlength: 6,
      },
      email: {
        required: true,
        email: true
      },
      telefono: {
        required: true,
        minlength: 6,
      }
    },
    submitHandler: function(form) {
      const data = $(form).serialize();
      console.log("data: ", data);

      $.ajax({
        type: "POST",
        url: "/api/iniziamo/submit",
        data: data,
        success: function(data,status) {
          $("#form-richiesta").hide();
          $(".form-success").show();
          $(".form-modal .close span").hide();
        },
        error: function(data,status) {
          $("#form-richiesta").hide();
          $(".form-error").show();
          $(".form-modal .close span").hide();
        },
        dataType: "json"
      });
      return false;
    },
    errorElement: "div",
    errorClass: "help-inline",
  });
  
  //INITIALIZING FORM
  $(".form-step__fieldset").hide();
  $("#step1").addClass("form-step__fieldset--active").show();

  // STEP 1
  $(document).on('click', '.btn-step-1', function() {
    if (validateForm.form()) {
      $(".form-step__fieldset").removeClass("form-step__fieldset--active").hide();
			$("#step2").addClass("form-step__fieldset--active").show();
      // ARROW NAV
      $('.form-modal__arrow.form-modal__arrow--prev').removeAttr('disabled');
      // PROGRESS STEP FORM
      const widthProgress = 100 / numStep * 1;
      $('.form-modal__progress').css({'width': widthProgress+'%'});
      //UPDATE CLASS NEXT ARROW
      $('.form-modal__arrow.form-modal__arrow--next').removeClass('btn-step-1').addClass('btn-step-2');
    }
  });

  // STEP 2
  $(document).on('click', '.btn-step-2', function() {
    if (validateForm.form()) {
      $(".form-step__fieldset").removeClass("form-step__fieldset--active").hide();
			$("#step3").addClass("form-step__fieldset--active").show();
      // PROGRESS STEP FORM
      const widthProgress = 100 / numStep * 2;
      $('.form-modal__progress').css({'width': widthProgress+'%'});
      //UPDATE CLASS NEXT ARROW
      $('.form-modal__arrow.form-modal__arrow--next').removeClass('btn-step-2').addClass('btn-step-3');
    }
  });

  // STEP 3
  $(document).on('click', '.btn-step-3', function() {
    if (validateForm.form()) {
      $(".form-step__fieldset").removeClass("form-step__fieldset--active").hide();
			$("#step4").addClass("form-step__fieldset--active").show();
      // PROGRESS STEP FORM
      const widthProgress = 100 / numStep * 3;
      $('.form-modal__progress').css({'width': widthProgress+'%'});
      //UPDATE CLASS NEXT ARROW
      $('.form-modal__arrow.form-modal__arrow--next').removeClass('btn-step-3').addClass('btn-step-4');
    }
  });

  // STEP 4
  $(document).on('click', '.btn-step-4', function() {
    if (validateForm.form()) {
      $(".form-step__fieldset").removeClass("form-step__fieldset--active").hide();
			$(".form-step__fieldset").show();
      $('.form-step').addClass('form-step--summary');
      $('.form-modal__progress').addClass('form-modal__progress--hide');
      $('.form-modal__nav').hide();
    }
  });

  // ARROW PREV NAV
  $(document).on('click', '.form-modal__arrow.form-modal__arrow--prev', function() {
    let currentStep = $('.form-step__fieldset--active');
    let prevStepNum = currentStep.prev('.form-step__fieldset').data('step');
    let prevStep = currentStep.prev('.form-step__fieldset').attr('id');
    
    if(typeof prevStep !== "undefined") {
      $('.form-step__fieldset').removeClass('form-step__fieldset--active').hide();
      $('#'+prevStep).addClass('form-step__fieldset--active').show();
      $('.form-modal__arrow.form-modal__arrow--next').removeClass(function (index, css) {
        return (css.match (/\bbtn-step-\S+/g) || []).join(' ');
      });
      $('.form-modal__arrow.form-modal__arrow--next').addClass('btn-step-'+prevStepNum);

      if(prevStepNum == 1) {
        $(this).attr('disabled', 'disabled');
      }
    }

    // PROGRESS STEP FORM
    const widthProgress = 100 / numStep * (prevStepNum-1);
    $('.form-modal__progress').css({'width': widthProgress+'%'});
  });
}